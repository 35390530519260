import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import { Failed, Paywall, Success, Trial } from '../features';
import { ROUTES } from '../constants/routes';
import { FullScreenLoader, ProtectedRoute, ScrollToTop, ThemeColor } from '../components';
import { AnalyticProvider } from '../contexts';
import { useGtagClientId } from '../hooks/use-gtag-client-id';
import { Welcome } from '../features/quiz/welcome';
import { Slider } from '../features/quiz/slider';
import { BotDetectionMiddleware } from '../middlewares/bot-filter';
import { getLSValue } from '../utils/locale-storage';
import { USER_DATA } from '../constants/common';
import { UserLocalStorageData } from '../types/user';
import { useUserTracking } from '../hooks/use-user-tracking';

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_GROWTHBOOK_DEV_MODE === 'true',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // eslint-disable-next-line no-console
    console.log('[Experiment]', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
  onFeatureUsage: (featureKey, result?: { value?: string }) => {
    // eslint-disable-next-line no-console
    console.log('[Feature]', { featureKey, result: result?.value });
  },
});

growthbook.init();

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const { gtagClientId } = useGtagClientId();
  const { trackUserData } = useUserTracking();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await trackUserData();
      } catch (e: unknown) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      if (!userLSData?.user_id) {
        return;
      }

      await growthbook.setAttributes({
        user_id: userLSData.user_id,
        device_id: window.navigator.userAgent,
        device_language: window.navigator.language?.toLowerCase() || 'en',
        browser: window.navigator.userAgent,
        url: window.location.host,
        client_id: gtagClientId || '',
      });
    })();
  }, [gtagClientId, userLSData]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AnalyticProvider>
        <BotDetectionMiddleware>
          <BrowserRouter>
            <ScrollToTop />
            <ThemeColor />
            <Routes>
              <Route path={ROUTES.INDEX_PAGE} element={<Welcome />} />
              <Route path={ROUTES.ABOUT} element={<Slider />} />

              <Route element={<ProtectedRoute checkEmail />}>
                <Route path={ROUTES.TRIAL_PAGE} element={<Trial />} />
                <Route path={ROUTES.PAYWALL_PAGE} element={<Paywall />} />
                <Route path={ROUTES.SUCCESS_PAGE} element={<Success />} />
                <Route path={ROUTES.FAILED_PAGE} element={<Failed />} />
              </Route>
              <Route path="*" element={<Welcome />} />
            </Routes>
          </BrowserRouter>
        </BotDetectionMiddleware>
      </AnalyticProvider>
    </GrowthBookProvider>
  );
}

export default App;
