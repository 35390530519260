const COOKIE_AGREEMENT_VALUE = 'cookie_agreement';
const TERMS_AGREEMENT_VALUE = 'terms_agreement';
const USER_DATA = 'user_data';
const MAX_STEPS = 8;
const SURVEY_STEPS_DATA = 'survey_steps_data';
const QUIZ_FINISHED = 'quiz_finished';

const MONEY_BACK_POLICY_LINK = 'https://wndr11.cy/files/Money-Back_Policy.pdf';

const BOT_REGEXP = new RegExp('bot|google|baidu|bing|msn|teoma|slurp|yandex', 'i');

export {
  MAX_STEPS,
  COOKIE_AGREEMENT_VALUE,
  USER_DATA,
  SURVEY_STEPS_DATA,
  TERMS_AGREEMENT_VALUE,
  QUIZ_FINISHED,
  MONEY_BACK_POLICY_LINK,
  BOT_REGEXP,
};
