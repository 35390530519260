import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo } from '../components/logo';
import welcomeImg from '../assets/images/welcome-img.png';
import checkIcon from '../assets/images/check-icon.svg';
import { Button } from '../../../components';
import { ROUTES } from '../../../constants/routes';

import styles from './welcome.module.scss';

const Welcome: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleContinueClick = () => {
    navigate({ pathname: ROUTES.ABOUT, search: location.search });
  };

  return (
    <div className={styles.container}>
      <Logo />

      <div className={styles.title_container}>
        <h4 className={styles.title}>
          Create your own <br /> masterpiece <span className={styles.blue_color}>with AI</span>
        </h4>

        <p className={styles.subtitle}>Fast and high-quality processing</p>
      </div>

      <div className={styles.img_container}>
        <img src={welcomeImg} alt="welcome" className={styles.welcome_img} />
      </div>

      <div className={styles.footer_container}>
        <Button variant="secondary" className={styles.continue_btn} onClick={handleContinueClick}>
          Continue
        </Button>

        <div className={styles.agreement}>
          <img src={checkIcon} alt="check" />
          <p>
            By tapping Continue, I agree to the{' '}
            <a href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK} target="_blank" rel="noreferrer noopener">
              Terms & Conditions
            </a>
            ,{' '}
            <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer noopener">
              Privacy Policy
            </a>
            ,{' '}
            <a href={process.env.REACT_APP_COOKIES_LINK} target="_blank" rel="noreferrer noopener">
              Cookie Policy
            </a>
            , and{' '}
            <a target="_blank" href={process.env.REACT_APP_SUBSCRIPTION_TERMS_LINK} rel="noreferrer noopener">
              Subscription Terms
            </a>
            . I also confirm that I am 18 or older and take full responsibility for the images I generate or edit.
          </p>
        </div>
      </div>
    </div>
  );
};

export { Welcome };
