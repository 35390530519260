import HttpService from 'src/services/http';

import {
  ConfigData,
  ConfigResponse,
  CreatedCustomerResponse,
  CustomerResponse,
  Metadata,
  PaypalPurchase,
  PaypalPurchaseResponse,
  RawCreatedCustomerResponse,
  RawCustomerResponse,
  Status,
  StripePurchase,
  StripePurchaseResponse,
  SubscriptionPurchase,
  SubscriptionPurchaseResponse,
} from './types';
import { mapCreatedCustomerResponse, mapCustomerResponse } from './mappers';

class WebApiService extends HttpService {
  constructor(baseUrl = '') {
    super(baseUrl);
  }

  async createCustomer(data: {
    metadata?: Metadata;
    test_values?: Record<string, any>;
  }): Promise<CreatedCustomerResponse> {
    try {
      const createdCustomer = await this.post<RawCreatedCustomerResponse>('funnel/customers', {
        app: process.env.REACT_APP_NAME_APP,
        metadata: data.metadata || {},
        ...(data.test_values ? { test_values: data.test_values } : {}),
      });

      return mapCreatedCustomerResponse(createdCustomer);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async getCustomerById(id: string): Promise<CustomerResponse> {
    try {
      const customer = await this.get<RawCustomerResponse>(`funnel/customers/${id}`);

      return mapCustomerResponse(customer);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async addEmailToCustomer(id: string, email: string): Promise<Status> {
    try {
      return await this.post<RawCustomerResponse>(`funnel/customers/${id}/email`, { email });
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async rewriteCustomerMetadata(id: string, metadata: Metadata): Promise<Status> {
    try {
      return await this.post<RawCustomerResponse>(`funnel/customers/${id}/metadata`, metadata);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async purchaseConfirm(purchaseId: string): Promise<Status> {
    try {
      return await this.post(`purchases/${purchaseId}/confirm`);
    } catch (error: unknown) {
      console.error('Network error', error);
      throw error;
    }
  }

  async getPaymentConfig(data: ConfigData): Promise<ConfigResponse> {
    try {
      const url = `configs?${new URLSearchParams(data).toString()}`;
      return await this.get(url);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async purchasePaypal(data: PaypalPurchase): Promise<PaypalPurchaseResponse> {
    try {
      return await this.post('purchases/paypal', data);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async purchaseStripe(data: StripePurchase): Promise<StripePurchaseResponse> {
    try {
      return await this.post('purchases/stripe', data);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }

  async purchaseSubscriptions(data: SubscriptionPurchase): Promise<SubscriptionPurchaseResponse> {
    try {
      return await this.post('purchases', data);
    } catch (error: unknown) {
      console.error('Network error', error);

      throw error;
    }
  }
}

export const webApiService = new WebApiService(process.env.REACT_APP_WEB_API);

export default webApiService;
