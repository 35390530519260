import { useCallback } from 'react';
import { isEqual } from 'lodash';

import useQueryParams from './use-query-params';
import { getLSValue, setLSValue } from '../utils/locale-storage';
import { cookieManager } from '../services/cookies';
import { UserLocalStorageData } from '../types/user';
import { BOT_REGEXP, USER_DATA } from '../constants/common';
import { ipInfoService } from '../services/ip';
import { webApiService } from '../services/web-api';
import userDataApiService from '../services/web-api/user-data-api';
import { getUserAgentInfo, OS } from '../utils/userAgentInfo';

const FB_UTM_SOURCES = ['facebook', 'meta'];

export const useUserTracking = () => {
  const { getQueryParams, updateQueryParams } = useQueryParams();
  const userLSData = getLSValue(USER_DATA, true) as UserLocalStorageData;

  const trackUserData = useCallback(async () => {
    const queryParams = getQueryParams();

    let adsData: Record<string, string> = {
      channel: (queryParams?.utm_source as string) || '',
      click_id: (queryParams?.clickid as string) || '',
    };

    if (FB_UTM_SOURCES.includes((queryParams?.utm_source as string)?.toLowerCase())) {
      const [fbpid, fbclid] = await Promise.all([
        cookieManager.waitForCookie('_fbp'),
        cookieManager.waitForCookie('_fbc'),
      ]);

      adsData = {
        channel: (queryParams?.utm_source as string) || '',
        pixel_id: process.env.REACT_APP_FACEBOOK_PIXEL_ID || '',
        fbc: fbclid || (queryParams.fbclid as string) || '',
        fbp: fbpid || (queryParams.fbp as string) || '',
      };
    }

    if (isEqual(userLSData?.ads_data || {}, adsData) && userLSData?.user_id) {
      if (!queryParams?.customer_id) {
        updateQueryParams({ customer_id: userLSData.user_id });
      }
      return; // means we have the same user
    }

    const userIp = await ipInfoService.getInfo();
    const userAgent = window.navigator.userAgent;
    const userAgentInfo = getUserAgentInfo();

    const isNotMobileUser = userAgentInfo.os !== OS.IosIphone && userAgentInfo.os !== OS.Android;
    const isBot = BOT_REGEXP.test(userAgent);

    // we create a new user if we are redirected from funnels
    const userResponse = await webApiService.createCustomer({
      metadata: {
        install: 'web_quiz',
      },
      ...(isNotMobileUser || isBot || !process.env.REACT_APP_BOT_REDIRECT_URL
        ? { test_values: { intro_email_template: 'password_reset_pwa' } }
        : {}),
    });

    const collectedData = {
      event_name: 'page_load',
      web_app_id: 'imagin8',
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      customer_id: userResponse.customerId,
      ip: userIp.ip || '',
      country: userIp.country || '',
      zip: userIp.postal || '',
      city: userIp.city || '',
      ads_network_data: [adsData],
    };

    setLSValue(USER_DATA, { user_id: userResponse.customerId, ads_data: adsData });
    updateQueryParams({ customer_id: userResponse.customerId });
    await userDataApiService.loadCustomerTracking(collectedData);
  }, [getQueryParams, updateQueryParams, userLSData]);

  return { trackUserData };
};
