import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import cardVisa from 'src/assets/images/card-visa.png';
import mastercard from 'src/assets/images/card-mastercard.png';
import maestro from 'src/assets/images/card-maestro.png';
import discover from 'src/assets/images/card-discover.png';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js';

import { Button, BUTTON_VARIANTS, Loading } from '../../../../components';
import { ROUTES } from '../../../../constants/routes';
import { webApiService } from '../../../../services/web-api';
import { useAnalytic } from '../../../../contexts';
import { getLSValue } from '../../../../utils/locale-storage';
import { USER_DATA } from '../../../../constants/common';
import { UserLocalStorageData } from '../../../../types/user';

import styles from './stripe-form.module.scss';

const DAYS_IN_WEEK = 7;
const CENTS_IN_DOLLAR = 100;

type StripeFormProps = {
  total: string;
  purchaseConfirm: (purchaseId: string) => void;
  setLoading: (state: boolean) => void;
  billingId: string;
};

const StripeForm = ({ total, purchaseConfirm, setLoading, billingId }: StripeFormProps) => {
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const navigate = useNavigate();
  const location = useLocation();
  const elements = useElements();
  const stripe = useStripe();
  const { sendEvent } = useAnalytic();
  const [submitting, setSubmitting] = useState(false);
  const changeEventSent = useRef(false);

  const plan = currentUserData.plan;

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');
      element?.on('ready', () => {
        setLoading(false);
      });
    }
  }, [elements, setLoading]);

  const getPurchase = async () => {
    try {
      const data = await webApiService.purchaseSubscriptions({
        app: process.env.REACT_APP_NAME_APP || '',
        billing_id: billingId || '',
        billing_type: 'stripe',
        customer_id: currentUserData.user_id,
        paypal: {
          app: process.env.REACT_APP_NAME_APP || '',
          customer_id: currentUserData.user_id,
          plan: currentUserData.plan?.introPlanKey || '',
        },
        stripe: {
          customer_id: currentUserData.user_id,
          intro_plan_key: currentUserData.plan?.introPlanKey || '',
          main_plan_key: currentUserData.plan?.mainPlanKey || '',
          trial_duration: currentUserData.plan?.trialDuration || 0,
        },
      });

      return data;
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const handlePaymentComplete = (result: Record<string, any>, purchaseId: string) => {
    if (result.error?.type && result.error?.type !== 'validation_error') {
      sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
        status: 'error',
        paymentMethod: result?.error?.payment_method as string,
        ...currentUserData,
      });

      navigate({ pathname: ROUTES.FAILED_PAGE, search: location.search });
    }

    if (result.error?.type && result.error?.type === 'validation_error') {
      setSubmitting(false);
    }

    if (result?.paymentIntent?.status === 'succeeded') {
      sendEvent('PerkyOnboardingPaywallSubscriptionPurchased', {
        status: 'success',
        paymentMethod: result.paymentIntent?.payment_method as string,
        ...currentUserData,
      });

      purchaseConfirm(purchaseId);

      navigate({ pathname: ROUTES.SUCCESS_PAGE, search: location.search });
    }
  };

  const handleExpressCheckoutConfirm = async () => {
    if (!stripe || !elements) {
      return;
    }

    setSubmitting(true);

    sendEvent('PerkyOnboardingPaywallPaymentAttempt', {
      ...currentUserData,
    });

    elements.submit();

    const purchase = await getPurchase();

    const result = await stripe.confirmPayment({
      elements,
      clientSecret: purchase?.stripe?.client_secret || '',
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/${ROUTES.SUCCESS_PAGE}`,
        payment_method_data: {
          billing_details: {
            address: {
              country: 'GB',
            },
          },
        },
      },
    });

    handlePaymentComplete(result, purchase?.purchase_id || '');
  };

  const handleCardConfirm = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setSubmitting(true);

    sendEvent('PerkyOnboardingPaywallPaymentAttempt', {
      ...currentUserData,
    });

    elements.submit();

    const purchase = await getPurchase();

    const result = await stripe.confirmCardPayment(purchase?.stripe?.client_secret || '', {
      payment_method: {
        card: elements.getElement(CardNumberElement) || { token: '' },
        billing_details: {
          address: {
            country: 'GB',
          },
        },
      },
    });

    handlePaymentComplete(result, purchase?.purchase_id || '');
  };

  const handlePaymentElementChange = () => {
    if (!changeEventSent.current) {
      return;
    }

    changeEventSent.current = true;
  };

  const handleFormReady = () => {
    setLoading(false);
  };

  const expressCheckoutOptions = {
    paymentMethods: {
      link: 'never',
      amazonPay: 'never',
      paypal: 'never',
    },
  } as StripeExpressCheckoutElementOptions;

  return (
    <>
      <ExpressCheckoutElement
        onConfirm={handleExpressCheckoutConfirm}
        className={styles.wallet_button}
        onReady={handleFormReady}
        options={expressCheckoutOptions}
      />

      <div className={styles.divider}>or</div>
      <form onSubmit={handleCardConfirm}>
        <div className={styles.variants}>
          <div className={styles.variantsTitle}>Pay with card</div>
          <ul className={styles.cards}>
            <li className={styles.card}>
              <img className={clsx(styles.visa, styles.cardImage)} src={cardVisa} alt="Visa" />
            </li>
            <li className={styles.card}>
              <img className={clsx(styles.mastercard, styles.cardImage)} src={mastercard} alt="Mastercard" />
            </li>
            <li className={styles.card}>
              <img className={clsx(styles.maestro, styles.cardImage)} src={maestro} alt="Maestro" />
            </li>
            <li className={styles.card}>
              <img className={clsx(styles.discover, styles.cardImage)} src={discover} alt="Discover" />
            </li>
          </ul>
        </div>
        <div className={styles.custom_form}>
          <CardNumberElement
            options={{
              classes: {
                base: styles.base_number,
              },
              placeholder: 'Card number',
            }}
            onChange={handlePaymentElementChange}
            onReady={handleFormReady}
          />
          <CardExpiryElement
            options={{
              classes: {
                base: styles.base_date,
              },
              placeholder: 'MM/YY',
            }}
          />
          <CardCvcElement
            options={{
              classes: {
                base: styles.base_code,
              },
              placeholder: 'CVV',
            }}
          />
        </div>

        <div className={styles.total}>
          Total: <span>{total}</span>
        </div>
        <Button className={styles.payButton} disabled={!stripe || submitting} variant={BUTTON_VARIANTS.SECONDARY}>
          <div className={styles.loadingText}>{submitting && <Loading className={styles.loading} />} PAY</div>
        </Button>
        <div className={styles.info}>
          You will be charged only {plan?.currencySymbol || '$'}
          {Number(plan?.trialPrice || '99') / CENTS_IN_DOLLAR} for your {plan?.trialDuration || DAYS_IN_WEEK}-day trial.
          Once the trial ends, you will be charged {plan?.currencySymbol || '$'}
          {Number(plan?.price) / CENTS_IN_DOLLAR}/{plan?.planPeriod || 'month'}. Cancel anytime. The charge will appear
          on your bill as IMAGIN8 APP for card payments and WNDR11 LTD for PayPal transactions. <br />{' '}
          {process.env.REACT_APP_COMPANY_ADDRESS} <br /> {process.env.REACT_APP_COMPANY_EMAIL}
        </div>
      </form>
    </>
  );
};

export { StripeForm };
