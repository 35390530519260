import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { P_TRIAL_PAYMENT_LAYOUT } from 'src/constants/growthbook-features';

import { useGrowthBook } from '@growthbook/growthbook-react';

import { VariantB } from './variant-b';
import { VariantA } from './variant-a';
import { ConfigResponse, webApiService } from '../../services/web-api';
import { getLSValue } from '../../utils/locale-storage';
import { USER_DATA } from '../../constants/common';
import { UserLocalStorageData } from '../../types/user';
import { useAnalytic } from '../../contexts';

const Paywall = () => {
  const currentUserData = useMemo(() => getLSValue(USER_DATA, true) as UserLocalStorageData, []);
  const growthBook = useGrowthBook();
  const [paymentConfig, setPaymentConfig] = useState<ConfigResponse>();
  const { sendEvent } = useAnalytic();

  const paywallScreenEventSent = useRef(false);

  const purchaseConfirm = useCallback(async (purchaseId: string) => {
    try {
      await webApiService.purchaseConfirm(purchaseId);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (paywallScreenEventSent.current) {
      return;
    }

    paywallScreenEventSent.current = true;

    const { plan } = currentUserData;
    sendEvent('PerkyOnboardingPaywallViewed', {
      plan: plan?.plan,
      trialPrice: plan?.trialPrice,
      price: plan?.price,
      currency: plan?.currency,
    });
  }, [sendEvent, currentUserData]);

  useEffect(() => {
    const initPurchases = async () => {
      try {
        const config = await webApiService.getPaymentConfig({
          app: process.env.REACT_APP_NAME_APP || '',
          customer_id: currentUserData.user_id,
        });

        if (config.checkout) {
          setPaymentConfig(config);
        }
      } catch (error: unknown) {
        console.error(error);
      }
    };

    initPurchases();
  }, [currentUserData.user_id]);

  return !growthBook?.isOn(P_TRIAL_PAYMENT_LAYOUT) ? (
    <VariantA purchaseConfirm={purchaseConfirm} paymentConfig={paymentConfig} />
  ) : (
    <VariantB purchaseConfirm={purchaseConfirm} paymentConfig={paymentConfig} />
  );
};

export { Paywall };
