import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';

import { Logo } from '../components/logo';
import { Slide } from '../components/slide';
import slide1Img from '../assets/images/slide_1.png';
import slide2Img from '../assets/images/slide_2.png';
import { Button } from '../../../components';
import { EmailModal } from '../components/email-modal';
import { ROUTES } from '../../../constants/routes';
import { QUIZ_FINISHED } from '../../../constants/common';
import { setLSValue } from '../../../utils/locale-storage';

import styles from './slider.module.scss';

const SLIDES = [
  {
    id: 0,
    title: (
      <h4 className={styles.slide_title}>
        <span className={styles.blue_text}>Describe</span> what you <br /> would like to see
      </h4>
    ),
    description: (
      <p className={styles.slide_description}>
        Turn your words <br /> into <span className={styles.blue_text}>artwork and realistic images</span>
      </p>
    ),
    img: slide1Img,
  },
  {
    id: 1,
    title: (
      <h4 className={styles.slide_title}>
        One tap, and your <br /> art piece is <span className={styles.blue_text}>ready</span>!
      </h4>
    ),
    description: (
      <p className={styles.slide_description}>
        Anyone, from realistic <br /> to the cutest fairytale
      </p>
    ),
    img: slide2Img,
  },
];

const Slider: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    setLSValue(QUIZ_FINISHED, true);
  }, []);

  const handleContinueClick = () => {
    const nextSlide = currentSlide + 1;
    if (nextSlide < SLIDES.length) {
      setCurrentSlide(nextSlide);

      return;
    }

    setShowEmailModal(true);
  };

  const handleEmailComplete = () => {
    setShowEmailModal(false);

    navigate({
      pathname: ROUTES.TRIAL_PAGE,
      search: location.search,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo_container}>
        <Logo />
      </div>

      {SLIDES.map(({ title, description, img, id }, i) => (
        <div
          key={id}
          className={clsx(styles.slide, {
            [styles.slide_hidden]: i !== currentSlide,
          })}
        >
          <Slide title={title} description={description} img={img} />
        </div>
      ))}

      <div className={styles.button_container}>
        <Button variant="secondary" className={styles.continue_btn} onClick={handleContinueClick}>
          Continue
        </Button>
      </div>

      <div className={styles.slider_dots_container}>
        <div className={styles.slider_dots}>
          {SLIDES.map(({ id }, index) => (
            <span key={id} className={clsx(styles.slider_dot, index === currentSlide && styles.slider_dot_active)} />
          ))}
        </div>
      </div>

      {showEmailModal && <EmailModal onComplete={handleEmailComplete} />}
    </div>
  );
};

export { Slider };
